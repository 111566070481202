<template>
  <v-timeline-item color="undefined">
    <template v-slot:icon>
      <v-icon :color="colorClass">{{ iconName }}</v-icon>
    </template>

    <v-row>
      <v-col cols="12" md="5">
        <div :class="columnHeaderClass">Now</div>
        <div :class="columnBodyClass">
          {{ currenDateTime }}
        </div>
      </v-col>
    </v-row>
  </v-timeline-item>
</template>

<script>
// design
import { iconCalendarToday } from "@/design/icon/iconConst";
import { getSecondaryColorClass } from "@/design/colors/Color";

// filters
import { toLocalDateTimeString } from "@/filters/dateFilter";

// mixins
import { timelineMixin } from "@/mixins/shared/timeline/timelineMixin";

export default {
  name: "TimelineItemNow",
  mixins: [timelineMixin],
  data() {
    return {
      iconName: iconCalendarToday,
      colorClass: getSecondaryColorClass()
    };
  },
  computed: {
    currenDateTime() {
      return toLocalDateTimeString(new Date().toString());
    }
  }
};
</script>
